import React from "react"
import { graphql } from "gatsby"
import PageTypes from "../../components/page-types/_PageTypes"

const StoryblokEventArchivePageTemplate = ({ data, venue, location }) => {
  const node = data.entry
  const content = JSON.parse(node.content)
  const navigationNode = data.navigation
  const navigation = navigationNode ? JSON.parse(navigationNode.content) : {}

  //   console.log("Storyblok Content", content)

  const events = data.events.nodes
    .map((el) => {
      const content = JSON.parse(el.content)
      //console.log(content.dates)
      return {
        headline: content.title ?? el.name,
        url: "/" + el.full_slug,
        kicker: content.kicker,
        type: content.category,
        // ? content.category.map((e) => capitalize(e)).join(", ")
        // : "Info",
        category: content.venue,
        // ? capitalize(content.venue)
        // : capitalize(content.venue),
        image: content.image,
        date: (content.dates && content.dates[0]) || " ",
        moreDates: content.dates && content.dates.length > 1,
      }
    })
    .filter((event) => {
      const currentDate = new Date()
      const eventDate = () => {
        // safari needs the date to be in the format of MM/DD/YYYY hh:mm
        const date = event.date?.datetime?.split(" ")[0]
        const time = event.date?.datetime?.split(" ")[1]
        const formatted = date?.split("-")
        const year = formatted && formatted[0]
        const month = formatted && formatted[1]
        const day = formatted && formatted[2]
        return new Date(`${month}/${day}/${year} ${time}`)
      }
      if (eventDate() < currentDate) {
        return event
      }
    })

  return React.createElement(PageTypes(content.component), {
    key: node.uid,
    content: content,
    name: node.name,
    type: content.component,
    navigation: navigation,
    venue: content.venue,
    location: location,
    events: events,
  })
}

export const query = graphql`
  query StoryblokEventArchiveQuery($id: String!, $venue: String) {
    entry: storyblokEntry(id: { eq: $id }) {
      content
      name
    }
    events: allStoryblokEntry(
      filter: {
        field_venue_string: { eq: $venue }
        field_component: { eq: "event_page" }
      }
    ) {
      nodes {
        name
        full_slug
        content
      }
    }
    navigation: storyblokEntry(full_slug: { eq: "global-navigation" }) {
      content
    }
  }
`

export default StoryblokEventArchivePageTemplate
